window.onscroll = function() {
  const nav = document.querySelector("#navbar");
  const link = document.querySelector("#nav-links");

  if (this.scrollY <= 10) {
    nav.className = "navbar fixed-top";
    link.className = "col-12 d-md-none row nav-links d-none";
  } else {
    nav.className = "navbar fixed-top scroll";
    link.className = "col-12 d-md-none row nav-links d-flex";
  }
};
